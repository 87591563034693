import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MailForm from '../components/contact/MailForm';
import ClientSlider from '../components/home/ClientSlider';
import SolutionSlider from '../components/home/SolutionSlider';

import AboutUs from '../assets/images/about-1-new.png';
import UnsplashFirst from '../assets/images/unsplash-1-new.svg';
import UnsplashSecond from '../assets/images/unsplash-2-new.svg';
import UnsplashThird from '../assets/images/unsplash-3-new.svg';

const Home = () => {
  return (
    <div className="home">
      {/* About us */}
      <section className="custom-hidden about container mt-14 min-h-[800px] md:mt-16 lg:mt-28">
        <h4 className="mb-6 text-xl font-medium xs:text-2xl md:text-3xl">
          About us
        </h4>

        <div className="about-top mb-5 md:flex md:items-center md:justify-between">
          <h2 className="w-full text-2xl font-bold xs:text-3xl md:w-[60%] md:text-4xl">
            Listen to you, <br />
            answer with solutions
          </h2>
          <div className="my-6 md:my-0 xl:transition-all xl:hover:scale-[1.01]">
            <Link
              className="cursor-pointer rounded-[46px] bg-[#0C66E4] py-[12px] px-[32px] text-center text-sm font-normal text-[#FFFFFF] xs:text-base md:self-center lg:text-lg"
              to="/about-us"
            >
              Learn more
            </Link>
          </div>
        </div>

        <div className="about-bottom relative">
          <img
            src={AboutUs}
            alt=""
            className="h-[364px] w-full rounded-[20px] object-cover lg:min-h-[394px]"
          />
          <div className="absolute left-[50%] top-[70%] w-[90%] translate-x-[-50%] rounded-[20px] bg-[#FAFBFF] p-[24px] shadow-2xl md:left-[70%] md:w-[50%]">
            <h4 className="mb-4 text-lg font-medium xs:text-xl md:text-2xl">
              About us
            </h4>
            <p className="text-sm font-normal xs:text-base lg:text-lg">
              BZCOM is an international IT company based in Hanoi. We specialize
              in partnering with government organizations and developing
              management systems that contribute greatly to the ongoing
              digitalization in Vietnam. Many of our clients are responsible for
              large-scale, multiyear projects involving national budgets and
              long-term investments.
            </p>
          </div>
        </div>
      </section>

      {/* Service */}
      <section className="custom-hidden service container mt-36 xs:mt-24 sm:mt-0">
        <h4 className="mb-6 text-xl font-medium xs:text-2xl md:text-3xl">
          Services
        </h4>

        <div className="mb-10 md:flex md:items-end md:justify-between 2xl:mb-16">
          <h2 className="text-2xl font-bold xs:text-3xl md:text-4xl">
            As BZCOM’s partners, <br /> you can get…
          </h2>
        </div>

        <ul className="box-scroll grid grid-flow-col gap-8 pb-2 sm:gap-3 md:gap-4 lg:grid-cols-3 lg:gap-6 xl:gap-10 2xl:gap-x-16">
          <li className="item custom__hidden-slider min-w-[271px] max-w-[400px]">
            <img
              className="mb-4 rounded-[20px] object-cover opacity-80 md:h-[160px] xl:h-[200px]"
              src={UnsplashFirst}
              alt=""
            />

            <h4 className="pb-6 text-lg font-semibold xs:text-xl md:text-2xl 1xl:pt-4">
              IT Consultation
            </h4>
            <p className="text-sm font-normal xs:text-base lg:text-justify lg:text-lg">
              Through our experiences and knowledge in the field, we are here to
              help you find your solution quickly and easily.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[271px] max-w-[400px]">
            <img
              className="mb-4 rounded-[20px] object-cover opacity-80 md:h-[160px] xl:h-[200px]"
              src={UnsplashSecond}
              alt=""
            />
            <h4 className="mb-6 text-lg font-semibold xs:text-xl md:text-2xl 1xl:pt-4">
              IT Outsource
            </h4>
            <p className="text-sm font-normal xs:text-base lg:text-justify lg:text-lg">
              We offer outsourcing service for our clients. We can save you not
              only resources, time, but money also.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[271px] max-w-[400px]">
            <img
              className="mb-4 rounded-[20px] object-cover opacity-80 md:h-[160px] xl:h-[200px]"
              src={UnsplashThird}
              alt=""
            />
            <h4 className="mb-6 text-lg font-semibold xs:text-xl md:text-2xl 1xl:pt-4">
              IT Solutions
            </h4>
            <p className="text-sm font-normal xs:text-base lg:text-justify lg:text-lg">
              If your problems are troubling you, our solutions might be the
              answer you are looking for.
            </p>
          </li>
        </ul>
      </section>

      {/* Solution */}
      <section className="custom-hidden solution container mt-14 overflow-hidden md:mt-16 lg:mt-28">
        <h4 className="mb-6 text-xl font-medium xs:text-2xl md:text-3xl">
          Solution
        </h4>
        <h2 className="mb-10 text-2xl font-bold xs:text-3xl md:text-4xl 2xl:mb-16">
          Elevate your businesses with our solutions
        </h2>
        <SolutionSlider />
      </section>

      {/* Clients */}
      <section className="custom-hidden mt-14 min-h-[250px] overflow-x-hidden bg-[#f6fafe] py-11 md:mt-16 lg:mt-28">
        <div className="container">
          <h4 className="mb-6 text-xl font-medium xs:text-2xl md:text-3xl">
            Clients & Partnership
          </h4>
          <ClientSlider
            infinite={false}
            autoplay={false}
            autoplayLg={true}
            classImage={'h-20 lg:h-24'}
          />
        </div>
      </section>

      {/* Contact us */}
      <section className="custom-hidden contact container my-14 md:my-16 lg:my-28">
        <h4 className="my-10 text-center text-xl font-medium xs:text-2xl md:text-3xl">
          Contact us
        </h4>

        <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
          <div className="mb-[40px] w-full lg:mb-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.1352638983276!2d105.7826301153321!3d21.02727309320405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abb070185ba1%3A0x4e6baae5cfbabd39!2sBZCOM!5e0!3m2!1sen!2s!4v1608629856337!5m2!1sen!2s"
              className="h-[450px] w-full rounded-xl"
              id="gg-map"
              aria-hidden="false"
              tabIndex="0"
              title="gg-map"
            ></iframe>
          </div>

          <div className="w-full xs:h-auto lg:h-full">
            <MailForm
              className="hover:bg-black"
              classForm="contact-form flex h-full flex-col justify-between"
              classInput="h-full w-full rounded-xl border border-[#C0C0C0] py-4 px-6"
              classTextarea="h-[180px] w-full rounded-xl border border-[#C0C0C0] py-4 px-6"
              classBtn="rounded-xl py-4 text-lg font-medium bg-[#0C66E4] text-white xl:transition-all xl:hover:scale-[1.01]"
            />
          </div>
        </div>
      </section>

      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default Home;
