import emailjs from '@emailjs/browser';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MailForm = (data) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();
  const recaptchaRef = useRef(null); // Ref để kiểm soát reCAPTCHA

  // Hiệu ứng để ẩn thông báo sau 2,5 giây
  useEffect(() => {
    let timer;
    if (isOpen && isLoading == false) {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, 2500);
    }
    return () => clearTimeout(timer); // Dọn dẹp nếu component bị hủy
  }, [isOpen, isLoading]);

  // Hàm để xử lý kết quả từ reCAPTCHA
  const handleCaptcha = (value) => {
    if (value) {
      setCaptchaVerified(true); // Đánh dấu captcha đã được xác nhận
    } else {
      setCaptchaVerified(false); // Nếu không có giá trị, đánh dấu là chưa xác nhận
    }
  };

  const onSubmit = async () => {
    if (!captchaVerified) {
      toast.error('Please verify that you are not a robot', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    setIsOpen(true);
    setIsLoading(true);

    emailjs
      .sendForm(
        'service_5na883b',
        'template_o3zyex8',
        form.current,
        'DR6zFggj1oKokdbGE'
      )
      .then(
        (result) => {
          reset();
          recaptchaRef.current.reset();
          setCaptchaVerified(false);
          setIsLoading(false);
          console.log('result', result);
        },
        (error) => {
          toast.error('Error! An error occurred. Please try again later', {
            position: toast.POSITION.TOP_CENTER
          });
          setIsLoading(false);
          setIsOpen(false);
          console.log(error.text);
        }
      );

    // try {
    //   const response = await fetch(`${backendUrl}/send-email`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
    //   });

    //   if (response.ok) {
    //     reset();
    //     recaptchaRef.current.reset();
    //     setCaptchaVerified(false);
    //     setIsLoading(false);
    //   } else {
    //     toast.error('Gửi email thất bại. Vui lòng thử lại.', {
    //       position: toast.POSITION.TOP_CENTER
    //     });
    //   }
    // } catch (error) {
    //   toast.error('Gửi email thất bại. Vui lòng thử lại.', {
    //     position: toast.POSITION.TOP_CENTER
    //   });
    //   setIsLoading(false);
    //   setIsOpen(false);
    // }
  };

  return (
    <div>
      <form
        className={data?.classForm}
        ref={form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <input
            className={`${data?.classInput} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            type="text"
            {...register('name', { required: 'Name is required' })}
            placeholder="Your name *"
          />
          {errors.name && (
            <span className="text-red-500">{errors.name.message}</span>
          )}
        </div>

        <div className="mt-3">
          <input
            className={`${data?.classInput} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            type="email"
            {...register('email', { required: 'Email is required' })}
            placeholder="Your email *"
          />
          {errors.email && (
            <span className="text-red-500">{errors.email.message}</span>
          )}
        </div>

        <div className="mt-3">
          <textarea
            className={`${data?.classTextarea} outline-none transition duration-300 ease-in-out focus:border-blue-500 focus:ring-1 focus:ring-blue-500`}
            {...register('message', { required: 'Message is required' })}
            placeholder="Your message *"
          ></textarea>
          {errors.message && (
            <span className="text-red-500">{errors.message.message}</span>
          )}
        </div>

        {/* Tích hợp Google reCAPTCHA */}
        <div className="mt-4">
          <ReCAPTCHA
            sitekey="6LeCTIoqAAAAADiy9bdMgU-8Ii2VdjXMn-xbq2Bk" // Thay site key ở đây
            onChange={handleCaptcha} // Lắng nghe sự kiện khi người dùng xác nhận CAPTCHA
            ref={recaptchaRef}
          />
        </div>

        <button
          className={`${data?.classBtn} mt-2 transition duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg`}
          type="submit"
        >
          Send
        </button>
      </form>

      {/* Thông báo gửi thành công */}
      <div
        className={`fixed inset-0 flex items-center justify-center   transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
      >
        {isLoading ? (
          <div
            className={`flex h-[230px] w-10/12 transform  items-center justify-center rounded-lg bg-white p-6 text-center shadow-[0px_0px_8px_0px_rgba(0,0,0,0.15)]  transition-transform duration-300 ease-in-out md:w-1/3 ${
              isOpen ? 'scale-100' : 'scale-95'
            }`}
          >
            <div className="loader"></div>
          </div>
        ) : (
          <div
            className={`h-[230px] w-10/12 transform rounded-lg bg-white p-6 text-center shadow-[0px_0px_8px_0px_rgba(0,0,0,0.15)]  transition-transform duration-300 ease-in-out md:w-1/3 ${
              isOpen ? 'scale-100' : 'scale-95'
            }`}
          >
            <button
              className="btn-gradient-1 absolute -right-10 top-0 mt-4 w-[80px]  "
              onClick={() => setIsOpen(false)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.03995 6.99906L13.3134 0.713121C13.4018 0.608657 13.3274 0.449951 13.1908 0.449951H11.5877C11.4933 0.449951 11.4029 0.492139 11.3406 0.56446L6.99129 5.74951L2.64196 0.56446C2.58169 0.492139 2.49129 0.449951 2.39486 0.449951H0.791735C0.655128 0.449951 0.580797 0.608657 0.66919 0.713121L5.94263 6.99906L0.66919 13.285C0.649389 13.3083 0.636686 13.3368 0.632589 13.3671C0.628491 13.3973 0.633171 13.4282 0.646074 13.4559C0.658976 13.4836 0.67956 13.507 0.705379 13.5234C0.731198 13.5397 0.76117 13.5483 0.791735 13.5482H2.39486C2.48928 13.5482 2.57968 13.506 2.64196 13.4337L6.99129 8.24861L11.3406 13.4337C11.4009 13.506 11.4913 13.5482 11.5877 13.5482H13.1908C13.3274 13.5482 13.4018 13.3895 13.3134 13.285L8.03995 6.99906Z"
                  fill="#787878"
                />
              </svg>
            </button>
            <div className="flex justify-center">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="30" cy="30" r="30" fill="#06941D" />
                <path
                  d="M44.3931 20.094L25.7948 38.6923L17.341 30.2385"
                  stroke="white"
                  strokeWidth="6.93642"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <h2 className="my-2 text-lg font-bold">
              Thank You for Contacting Us!
            </h2>
            <p className="font-normal text-[#6B7280]">
              Your request has been sent successfully. Our team will get in
              touch with you shortly to assist.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MailForm;
