import 'animate.css';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useModal } from '../../../../contexts/ModalContext';

const customStyles = {
  overlay: {
    zIndex: 999,
    overflow: 'auto'
  },
  content: {
    padding: '0',
    borderRadius: '0px',
    overflow: 'auto',
    transform: 'none',
    right: 'auto',
    bottom: 'auto',
    width: '100vw',
    height: '100vh'
  }
};
const HighlightModal = () => {
  const version = new Date().getTime();
  // eslint-disable-next-line no-undef
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}?v=${version}`;
  // eslint-disable-next-line no-undef
  const videoUrl = `${process.env.REACT_APP_VIDEO_URL}?v=${version}`;
  const { hasSeenModal, markModalAsSeen } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    handleClose();
    markModalAsSeen();
    document.body.style.overflow = 'auto';
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
    }, 1000);
  };

  useEffect(() => {
    const checkContentAvailability = async () => {
      let hasImage = false;
      let hasVideo = false;
      let isOpen = true;

      try {
        if (imageUrl) {
          const response = await fetch(imageUrl, { method: 'HEAD' });
          hasImage = response.ok;
        }
        if (videoUrl) {
          const response = await fetch(videoUrl, { method: 'HEAD' });
          hasVideo = response.ok;
        }
      } catch (error) {
        console.error('Error fetching URLs:', error);
      }

      if (hasImage && hasVideo) {
        // console.log('Ngẫu nhiên');
        // setContent(Math.random() < 0.5 ? 'image' : 'video');
        setContent('video');
      } else if (hasImage) {
        // console.log('Image');
        setContent('image');
      } else if (hasVideo) {
        // console.log('Video');
        setContent('video');
      } else {
        // console.log('Cả hai không có dữ liệu');
        setContent('none');
        isOpen = false;
        document.body.style.overflow = 'auto';
      }
      return isOpen;
    };

    if (!hasSeenModal) {
      const timer = setTimeout(async () => {
        const valueOpen = checkContentAvailability();
        if (valueOpen) {
          document.body.style.overflow = 'hidden';
        }
        setIsOpen(valueOpen);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [hasSeenModal]);

  if (content === 'none' || content === null) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Popup"
      className={`focus:outline-none  ${
        isClosing
          ? 'animate__animated animate__zoomOut'
          : 'animate__animated animate__zoomIn'
      } relative overflow-hidden rounded-lg p-6 outline-none transition-opacity duration-300`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-40"
      closeTimeoutMS={500}
    >
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <div className="">
          <div className="flex justify-end">
            <button onClick={closeModal} className="text-lg font-semibold">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="white"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="h-auto xs:max-h-[533px] xs:min-w-[300px] xs:max-w-[332px] xs:px-4 sm:max-h-[540px] sm:max-w-[500px] md:max-h-[640px] md:max-w-[702px] md:px-8 1xl:max-h-[1024px] 1xl:max-w-[640px]">
            <div className="h-full max-h-full w-auto max-w-full overflow-hidden rounded bg-white shadow-lg">
              {content === 'video' ? (
                <div className="relative h-auto w-full">
                  <video
                    className="h-auto w-full rounded"
                    controls
                    autoPlay
                    muted
                  >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={imageUrl}
                  alt="Fallback Image"
                  className="h-auto w-auto rounded"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HighlightModal;
