import { HashRouter, Route, Routes } from 'react-router-dom';
import Layout from '../layout/Layout';
import Home from '../pages/Home';
import About from '../pages/sub/About';
import Contact from '../pages/sub/Contact';
import Service from '../pages/sub/Service';
import Solution from '../pages/sub/Solution';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="solution" element={<Solution />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
