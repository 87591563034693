import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Close from '../../assets/icons/close.svg';
import Menu from '../../assets/icons/menu.svg';
import Logo from '../../assets/images/logo.png';
import Bztalk from '../../assets/images/logobztalk.png';
import Bzware from '../../assets/images/logoBzware.png';
import Tms from '../../assets/images/logoTMS.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  // Effect để điều khiển thời gian mở/đóng
  useEffect(() => {
    let timer;
    if (isHovering) {
      timer = setTimeout(() => setIsOpenInternal(true), 100); // Trì hoãn mở 100ms
    } else {
      timer = setTimeout(() => setIsOpenInternal(false), 100); // Trì hoãn đóng 300ms để có hiệu ứng trượt lên
    }
    return () => clearTimeout(timer);
  }, [isHovering]);
  const lock = () => document.body.classList.add('lock');
  const unLock = () => document.body.classList.remove('lock');

  const openNav = () => {
    setIsClose(false);
    setIsOpen(true);
    lock();
  };
  const closeNav = () => {
    setIsOpen(false);
    setIsClose(true);
    unLock();
  };

  const handleLinkClick = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <header className="container">
      <div className="hidden  py-[16px] text-base font-medium lg:flex lg:items-center lg:justify-end">
        <Link
          className="text-sm text-[#E5E7EB] transition duration-150 hover:text-white"
          to="tel:+84 2432 004 887"
        >
          +84 (2432) 004 887
        </Link>

        <i className="mx-4 h-5 border-r-2 border-[#E5E7EB]"></i>

        <Link
          className="text-sm text-[#E5E7EB] transition duration-150 hover:text-white"
          to="tel:+84 983 104 170"
        >
          +84 (983) 104 170
        </Link>

        <i className="mx-4 h-5 border-r-2 border-[#E5E7EB]"></i>

        <Link
          className="text-[#E5E7EB] transition duration-150 hover:text-white"
          to="mailto: bzcomvina@gmail.com"
        >
          bzcomvina@gmail.com
        </Link>
      </div>

      <div className="nav flex items-center justify-between pt-[32px] lg:pt-0">
        <Link to="/">
          <img
            className="h-[40px] cursor-pointer object-cover md:h-[44px] lg:h-[48px] xl:h-[52px]"
            src={Logo}
            alt=""
          />
        </Link>

        <nav className="hidden lg:block">
          <ul className="flex h-full w-full items-center text-lg font-normal text-[#F9FAFB]">
            <li className="mr-[40px]">
              <NavLink to="/about-us" className="link relative cursor-pointer">
                About us
              </NavLink>
            </li>
            <li className="mr-[40px]">
              <NavLink to="/service" className="link relative cursor-pointer">
                Service
              </NavLink>
            </li>
            <li className="mr-[40px]">
              <NavLink to="/solution" className="link relative cursor-pointer">
                Solutions
              </NavLink>
            </li>
            <li
              className="relative mr-[40px] inline-block "
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <NavLink
                to="/solution"
                className="link relative flex cursor-pointer items-center gap-4"
              >
                Internal Systems
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L5 5.5L9 1.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </NavLink>
              {isOpenInternal && (
                <div
                  className={`absolute top-[42px] left-20 z-40 cursor-pointer ${
                    isOpenInternal
                      ? ' h-3 translate-y-0 opacity-100'
                      : 'max-h-0 -translate-y-4 opacity-0'
                  }`}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11.3137L0.343146 5.65687L4.58579 1.41423C5.36683 0.633177 6.63317 0.633177 7.41421 1.41423L11.6569 5.65687L6 11.3137Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}

              <div
                className={`absolute top-10 -left-24 z-[99] mt-2 w-[370px] overflow-hidden rounded-lg bg-white shadow-lg transition-all duration-300 ${
                  isOpenInternal
                    ? 'max-h-[500px] translate-y-0 opacity-100'
                    : 'max-h-0 -translate-y-4 opacity-0'
                }`}
              >
                <div className=" p-3">
                  <Link
                    onClick={(e) =>
                      handleLinkClick(e, 'https://bzware.bzcom.vn/')
                    }
                    to="https://bzware.bzcom.vn/"
                  >
                    <div className="mb-2 flex cursor-pointer items-center gap-4 p-2 hover:bg-gray-100 hover:shadow">
                      <div className="rounded-md bg-[#F3F3F3] p-2">
                        <img
                          className="rounded object-cover lg:h-full lg:w-full"
                          src={Bzware}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="text-base font-semibold text-black">
                          BZware
                        </p>
                        <p className="text-sm text-[#4B5563]">
                          All-in-one internal management software.
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    onClick={(e) => handleLinkClick(e, 'http://tms.bzcom.vn/')}
                    to="http://tms.bzcom.vn/"
                  >
                    <div className="mb-2 flex cursor-pointer items-center gap-4 p-2 hover:bg-gray-100 hover:shadow ">
                      <div className="rounded-md bg-[#F3F3F3] p-2">
                        <img
                          className="rounded object-cover lg:h-full lg:w-full"
                          src={Tms}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="text-base font-semibold text-black">
                          TMS
                        </p>
                        <p className="text-sm text-[#4B5563]">
                          Software for managing QA processes.
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    onClick={(e) => handleLinkClick(e, 'https://bzt.bzcom.vn/')}
                    to="https://bzt.bzcom.vn/"
                  >
                    <div className="flex cursor-pointer items-center gap-4 p-2 hover:bg-gray-100 hover:shadow">
                      <div className="rounded-md bg-[#F3F3F3] p-2">
                        <img
                          className="rounded object-cover lg:h-full lg:w-full"
                          src={Bztalk}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="text-base font-semibold text-black">
                          BZtalk
                        </p>
                        <p className="text-sm text-[#4B5563]">
                          Messaging software for personal.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                className="header-btn inline-flex cursor-pointer items-center justify-center px-6 font-medium text-[#333] transition duration-300 hover:bg-slate-300"
                to="/contact"
              >
                Contact us
                <i className="header__btn-dots ml-2">
                  <span className="m-1"></span>
                </i>
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Tablet - Mobile */}
        <div className="lg:hidden">
          <img
            className="h-[28px] w-[28px]"
            onClick={() => openNav()}
            src={Menu}
            alt=""
          />

          <div className="nav__mobile">
            <nav
              className={`${isOpen && 'nav__mobile-in'} ${
                isClose && 'nav__mobile-out'
              } fixed right-[-100%] z-10 flex w-full flex-col bg-white px-[16px] py-[32px] xs:w-[80%]`}
            >
              <img
                onClick={() => closeNav()}
                className="w-10 self-end"
                src={Close}
                alt=""
              />

              <ul className="self-center text-start text-base font-medium text-[#A0A0A0]">
                <li className="my-[32px]">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                    end
                  >
                    Home
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    About us
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/service"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Service
                  </NavLink>
                </li>
                <li className="mb-[32px]">
                  <NavLink
                    to="/solution"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Solutions
                  </NavLink>
                </li>
                <div
                  className="relative mr-[40px] mb-[32px] inline-block "
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <div className=" relative flex cursor-pointer items-center gap-4">
                    Internal Systems
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.5L5 5.5L9 1.5"
                        stroke="#A0A0A0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <div
                    className={` -left-24 z-[99] mt-2  overflow-hidden rounded-lg bg-white  transition-all duration-300 ${
                      isOpenInternal
                        ? 'max-h-[500px] translate-y-0 opacity-100'
                        : 'max-h-0 -translate-y-4 opacity-0'
                    }`}
                  >
                    <div className=" p-5 ">
                      <Link
                        onClick={(e) =>
                          handleLinkClick(e, 'https://bzware.bzcom.vn/')
                        }
                        to="https://bzware.bzcom.vn/"
                      >
                        <div className="mb-5 flex cursor-pointer items-center gap-4  ">
                          <div className="rounded-md bg-[#F3F3F3] p-2">
                            <img
                              className="rounded object-cover lg:h-full lg:w-full"
                              src={Bzware}
                              alt=""
                            />
                          </div>
                          <div>
                            <p className="text-sm font-semibold text-black">
                              BZware
                            </p>
                            <p className="text-sm text-[#4B5563]">
                              All-in-one internal management software.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        onClick={(e) =>
                          handleLinkClick(e, 'http://tms.bzcom.vn/')
                        }
                        to="http://tms.bzcom.vn/"
                      >
                        <div className="mb-5 flex cursor-pointer items-center gap-4">
                          <div className="rounded-md bg-[#F3F3F3] p-2">
                            <img
                              className="rounded object-cover lg:h-full lg:w-full"
                              src={Tms}
                              alt=""
                            />
                          </div>
                          <div>
                            <p className="text-sm font-semibold text-black">
                              TMS
                            </p>
                            <p className="text-sm text-[#4B5563]">
                              Software for managing QA processes.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <Link
                        onClick={(e) =>
                          handleLinkClick(e, 'https://bzt.bzcom.vn/')
                        }
                        to="https://bzt.bzcom.vn/"
                      >
                        <div className="flex cursor-pointer items-center gap-4">
                          <div className="rounded-md bg-[#F3F3F3] p-2">
                            <img
                              className="rounded object-cover lg:h-full lg:w-full"
                              src={Bztalk}
                              alt=""
                            />
                          </div>
                          <div>
                            <p className="text-sm font-semibold text-black">
                              BZtalk
                            </p>
                            <p className="text-sm text-[#4B5563]">
                              Messaging software for personal.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <li className="mb-[32px]">
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive ? 'active' : undefined
                    }
                    onClick={() => closeNav()}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>

            <div
              onClick={() => closeNav()}
              className={`${isOpen && 'overlay-in'} ${
                isClose && 'overlay-out'
              } fixed right-[-100%] bg-black opacity-30`}
            ></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
