import { useLocation } from 'react-router-dom';

const titleList = [
  {
    path: '/about-us',
    title: 'About us',
    description: `BZCOM is a Hanoi-based IT company that specialize in technical consulting and oursourcing`
  },
  {
    path: '/service',
    title: 'Services',
    description: `In BZCOM, we offer 3 main services, which are consultation, outsource and solutions`
  },
  {
    path: '/solution',
    title: 'Solutions',
    description: `Our solution provides a comprehensive and efficient way to streamline your business processes and increase productivity`
  },
  {
    path: '/contact',
    title: 'Contact us',
    description: `If you have any questions or needs, please feel free to contact us for more details information`
  }
];

const SubPageTitle = () => {
  const { pathname } = useLocation();

  return (
    <>
      {titleList.map((data, index) => {
        let title;
        if (pathname === data?.path) {
          title = (
            <section
              key={index}
              className="custom-hidden container flex h-[60%] flex-col items-center justify-center"
            >
              <h1 className="mb-4 text-xl font-bold text-white xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                {data?.title}
              </h1>
              <p
                className={`text-center text-sm font-normal text-[#E3E1F0] xs:text-base md:w-[80%] lg:text-[20px] lg:leading-[28px] ${
                  data.path === '/about-us' && 'xl:w-[500px]'
                } ${data.path === '/solution' && 'xl:w-[650px]'} ${
                  data.path === '/contact' && 'xl:w-[500px]'
                }`}
              >
                {data?.description}
              </p>
            </section>
          );
        }
        return title;
      })}
    </>
  );
};

export default SubPageTitle;
