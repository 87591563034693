import { createContext, useContext, useState } from 'react';

// Tạo Context Modal
const ModalContext = createContext();

// Hook để sử dụng context
export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [hasSeenModal, setHasSeenModal] = useState(false);

  // Hàm để đánh dấu modal đã được xem
  const markModalAsSeen = () => {
    setHasSeenModal(true); // Đánh dấu modal là đã xem
  };
  return (
    <ModalContext.Provider value={{ hasSeenModal, markModalAsSeen }}>
      {children}
    </ModalContext.Provider>
  );
};
