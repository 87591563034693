import Slider from 'react-slick';
import Slogan from './Slogan';

import { useEffect, useState } from 'react';
import banerimagebg from '../../assets/images/baner-bg-image.png';
import tmsf from '../../assets/images/tms-firts.png';
import tmss from '../../assets/images/tms-secornd.png';

const settings = {
  className: 'banner-slider h-full',
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 9000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Banner = () => {
  // eslint-disable-next-line no-unused-vars
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    // Hàm cập nhật kích thước màn hình
    const updateSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    // Lắng nghe sự kiện resize
    window.addEventListener('resize', updateSize);

    // Cleanup để gỡ bỏ sự kiện khi component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <section
      className={`banner container relative h-[710px] overflow-hidden xs:px-8 md:h-[650px] lg:h-[640px] xl:h-[700px]`}
    >
      <Slider {...settings}>
        <div className="custom-hidden slider-first overflow-hidden">
          <div className="custom-hidde container flex flex-col items-center py-9 md:flex-row md:items-center md:justify-around">
            <div className="w-full xs:max-h-[70%] xs:pl-[10%] sm:pl-[20%] md:max-h-full md:w-[50%] md:pl-0">
              <div className="relative md:mt-10 lg:mt-4">
                <img
                  src={banerimagebg}
                  alt=""
                  className="absolute -top-4 object-cover xs:-left-4 xs:w-[520px] xs:scale-150 sm:w-[520px] md:left-6 md:w-[560px] md:scale-150 lg:-left-[2px] lg:top-8 lg:w-[700px] xl:top-0 xl:scale-75 xl:pr-24 3xl:left-[5%]"
                />
                <img
                  src={tmsf}
                  alt=""
                  className="absolute top-0 object-cover xs:w-[140px] sm:w-[160px] md:w-[180px] lg:w-[220px] xl:top-[60px] xl:left-[60px] 1xl:w-[240px]"
                />
                <img
                  src={tmss}
                  alt=""
                  className="absolute top-[55px] object-cover xs:left-[80px] xs:w-[140px] sm:left-[120px] sm:w-[160px] md:w-[180px] lg:w-[220px] xl:top-[110px] xl:left-[190px] 1xl:w-[240px]"
                />
              </div>
            </div>
            <div className="flex max-h-[130px] w-full flex-col items-center justify-center gap-2 text-center text-white md:mb-8 md:w-[50%] md:items-start md:justify-center md:gap-6 md:text-left lg:mb-0">
              <div className="max-h-[32px] md:mb-4 lg:mb-0">
                <Slogan />
              </div>
              <h1 className="text-xl font-bold xs:text-[28px] sm:text-3xl md:text-4xl lg:text-5xl">
                We are your partner
              </h1>
            </div>
          </div>
        </div>

        <div className="custom-hidden overflow-hidden">
          <div className="custom-hidden container flex flex-col items-center py-9 md:flex-row md:items-center md:justify-around">
            <div className="w-full xs:max-h-[70%] xs:pl-[10%] sm:pl-[20%] md:max-h-full md:w-[50%] md:pl-0">
              <div className="relative md:mt-10 lg:mt-4">
                <img
                  src={banerimagebg}
                  alt=""
                  className="absolute -top-4 object-cover xs:-left-4 xs:w-[520px] xs:scale-150 sm:w-[520px] md:left-6 md:w-[560px] md:scale-150 lg:-left-[2px] lg:top-8 lg:w-[700px] xl:top-0 xl:scale-75 xl:pr-24 3xl:left-[5%]"
                />
                <img
                  src={tmsf}
                  alt=""
                  className="absolute top-0 object-cover xs:w-[140px] sm:w-[160px] md:w-[180px] lg:w-[220px] xl:top-[60px] xl:left-[60px] 1xl:w-[240px]"
                />
                <img
                  src={tmss}
                  alt=""
                  className="absolute top-[55px] object-cover xs:left-[80px] xs:w-[140px] sm:left-[120px] sm:w-[160px] md:w-[180px] lg:w-[220px] xl:top-[110px] xl:left-[190px] 1xl:w-[240px]"
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-2 text-center text-white xs:pt-6 md:mb-8 md:w-[50%] md:items-start md:justify-center md:gap-6 md:pl-8 md:pt-0 md:text-left lg:mb-0">
              {/* <div className="flex flex-col items-start justify-center text-center text-white md:w-[38%] md:text-left"> */}
              <h1 className="mb-4 text-xl font-bold xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                BzWare Solution
              </h1>
              <p className="text-left text-sm font-normal tracking-wide xs:text-center xs:text-base md:text-left lg:text-lg">
                When you need a scalable organization tool, we recommend that
                you go with our solution, BZWARE, which can help make sure every
                single one of your employees will get everything they are going
                through in order to accomplish their goals.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Banner;
