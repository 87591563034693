import { useRef } from 'react';
import bztmb from '../../assets/images/bztmb.png';
import Bzt from '../../assets/images/bztmes.png';
import Bzware from '../../assets/images/bzwareProject.png';
import LogoBztalk2 from '../../assets/images/logobztalk2.png';
import Bzware2 from '../../assets/images/logoBzware2.png';
import LogoTms2 from '../../assets/images/logoTms2.png';
import Tms from '../../assets/images/tms.png';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ClientSlider from '../../components/home/ClientSlider';

const Solution = () => {
  const swiperRef = useRef(null);

  return (
    <div className="solution custom-hidden container  bg-white">
      <div className="w-full">
        <div className="relative pt-16 ">
          <div className="relative m-auto xs:w-[80%] xl:w-[100%]">
            <p className=" relative font-bold xs:w-[311px] xs:text-[30px] sm:block  md:w-[500px] md:text-[35px] xl:w-[600px] xl:text-[40px]">
              Our Comprehensive Solution
            </p>
            <div className="hidden">
              <svg
                width="239"
                height="16"
                viewBox="0 0 484 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="xs:w-[239px] sm:w-[300px] md:w-[382px] lg:w-[480px]"
              >
                <path
                  d="M2 14C71.776 4.94452 265.462 -7.73316 482 14"
                  stroke="#54BD95"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col gap-24 ">
          {/* ----- Bzware ----*/}
          <div className="m-auto mt-10 items-center justify-center gap-28 xs:w-[80%] lg:flex xl:flex xl:w-[100%]">
            <div className="flex   flex-col gap-4">
              <div className="flex items-center gap-3 ">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={Bzware2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent ">
                  BZware
                </p>
              </div>
              <h2 className="font-bold text-[#1F2A37] xs:text-[24px] sm:text-[28px] md:text-[32px] xl:w-full ">
                Optimize & Manage
              </h2>
              <div className="relative m-auto xl:hidden">
                <img src={Bzware} alt="" className="object-cover" />
              </div>
              <p className=" sm:[350px] text-base font-normal text-[#4B5563] md:w-[604px] xl:w-[482px]">
                BZware offers the ultimate solution for workforce and project{' '}
                management, enabling seamless planning, tracking, and execution
                of tasks. With integrated tools to streamline workflows,
                allocate resources, and keep teams aligned, BZware enhances
                productivity and fosters collaboration. From project tracking to
                HR management, everything you need to drive efficiency and
                impact is in one platform.
              </p>
            </div>
            <div className="w- relative xs:hidden sm:hidden md:hidden xl:block">
              <img src={Bzware} alt="" className="object-cover" />
            </div>
          </div>
          {/*---- TMS ------  */}
          <div className="relative m-auto items-center justify-between gap-28 xs:w-[80%] lg:flex xl:mt-8 xl:flex xl:w-[100%]">
            <div className="relative xs:hidden sm:hidden md:hidden xl:block">
              <img src={Tms} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="relative  flex flex-col gap-4">
              <div className="flex items-center gap-3">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={LogoTms2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent">
                  TMS
                </p>
              </div>

              <h2 className="font-bold text-[#1F2A37] xs:text-[24px] sm:text-[28px] md:text-[32px] ">
                Simplify Your QA Process
              </h2>
              <div className="relative m-auto  xl:hidden">
                <img src={Tms} alt="" className="h-full w-full object-cover" />
              </div>
              <p className="text-base font-normal text-[#4B5563] xl:w-[484px]">
                TMS provides a complete solution for managing your quality
                assurance process, from planning and executing test cases to
                tracking results and ensuring quality at every stage. With tools
                to organize workflows, allocate resources, and maintain team
                alignment, TMS helps streamline your quality assurance efforts.
                From simple quality checks to complex scenarios, our platform
                empowers your team to deliver reliable, high-quality outcomes
                efficiently and consistently.
              </p>
            </div>
          </div>
          {/*---- Bztalk ------  */}
          <div className="relative  m-auto items-center justify-between gap-28 xs:w-[80%] lg:flex xl:flex  xl:w-[100%] xl:pt-10">
            <div className="relative flex   flex-col gap-3 ">
              <div className="flex items-center gap-3">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-[#195BDD]">
                  <img src={LogoBztalk2} alt="" />
                </div>

                <p className="bg-gradient-to-r  from-[#195BDD] via-[#5856D6] to-[#5856D6] bg-clip-text text-2xl font-semibold text-transparent">
                  BZtalk
                </p>
              </div>

              <h2 className="flex gap-2 font-bold text-[#1F2A37]  xs:text-[24px] sm:text-[28px] md:text-[32px] xl:w-full ">
                <span className="xs:hidden sm:block">Seamless</span> Team
                Communication
              </h2>
              <div className="    md:block xl:hidden">
                <div className="">
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className=" h-full max-w-[618px] "
                  >
                    <SwiperSlide>
                      <div className="pb-6">
                        <img
                          src={Bzt}
                          alt=""
                          className={` h-auto w-auto object-cover  `}
                          // onClick={() => handleIndicatorClick(false)}
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="pb-6">
                        <img
                          src={bztmb}
                          alt=""
                          className={` h-auto w-auto  object-cover `}
                          // onClick={() => handleIndicatorClick(true)}
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <p className=" text-base font-normal  text-[#4B5563] xl:w-[504px]">
                BZTALK is your ultimate messaging platform, designed to enhance
                communication within teams. With features like channels for
                group discussions and direct messaging for one-on-one
                conversations, you can easily share ideas, collaborate on
                projects, and stay connected. Our intuitive interface ensures
                that your team can communicate effectively, whether you’re in
                the office or working remotely. Experience a new level of
                collaboration with BZTALK, where every conversation matters.
              </p>
            </div>
            <div className="  xs:hidden xl:block">
              <Swiper
                ref={swiperRef}
                pagination={true}
                modules={[Pagination]}
                slidesPerView={1}
                className="h-full w-[618px]  "
              >
                <SwiperSlide>
                  <div className="pb-6 ">
                    <img
                      src={Bzt}
                      alt=""
                      className={`  h-full w-full object-cover  `}
                      // onClick={() => handleIndicatorClick(false)}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pb-6 ">
                    <img
                      src={bztmb}
                      alt=""
                      className={` h-full object-cover   `}
                      // onClick={() => handleIndicatorClick(true)}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* ----- End ----- */}
        </div>

        <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
          <ClientSlider
            infinite={true}
            autoplay={true}
            autoplayLg={true}
            classImage={
              'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
            }
          />
        </section>
      </div>
    </div>
  );
};

export default Solution;
