import ClientSlider from '../../components/home/ClientSlider';

import UnsplashFirst from '../../assets/images/unsplash-1-new.svg';
import UnsplashSecond from '../../assets/images/unsplash-2-new.svg';
import UnsplashThird from '../../assets/images/unsplash-3-new.svg';

const Service = () => {
  return (
    <div className="service bg-white">
      <section className="custom-hidden container xs:pt-4 md:pt-14 xl:pt-0">
        <div className="grid grid-cols-1 md:grid-cols-1 md:items-center md:justify-center">
          {/* <div className="col-span-1 flex">
            <img
              className="h-[80%] rounded-lg object-cover xl:mt-[-10%] xl:h-full"
              src={ServiceFirst}
              alt=""
            />
            <img
              className="relative top-[40%] left-[-70%] h-[80%] rounded-lg border-[16px] border-[#F3F6FE] object-cover xs:left-[-60%] xs:top-[30%] md:left-[-55%] md:mt-[60%] xl:mt-[30%] xl:h-full"
              src={ServiceSecond}
              alt=""
            />
          </div> */}

          <div></div>

          <div className="col-span-1 xs:pt-4 lg:pt-20 xl:w-[1224px]">
            <h3 className="mb-4 text-xl font-bold text-[#172B4D] xs:text-2xl md:text-[40px]">
              BZCOM COMPANY LIMITED SINCE 2017
            </h3>
            <p className="text-sm font-normal text-[#44546F] xs:text-base md:text-[16px] md:leading-[24px] lg:text-lg">
              Leading the 4th industrial revolution and developing various
              software solutions/services, It is a value partner that enhances
              customers’ business competitiveness and realizes business
              innovation. BzCom intends to fulfill its social responsibility by
              combining various businesses, which are the core competitiveness
              of modern society.
            </p>
          </div>
        </div>

        <div></div>
      </section>

      <section className="custom-hidden container hidden overflow-hidden pt-14 md:pt-16 lg:pt-28">
        <ul className="grid grid-flow-col gap-8 overflow-x-auto pb-2 sm:grid-cols-3 sm:gap-3 md:gap-4 lg:gap-6 xl:gap-10 2xl:gap-x-16">
          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashFirst}
              alt=""
            />

            <h4 className="pb-6 text-lg font-semibold text-[#412B26] xs:text-xl md:text-2xl">
              IT Consultation
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              Through our experiences and knowledge in the field, we are here to
              help you find your solution quickly and easily.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashSecond}
              alt=""
            />
            <h4 className="mb-6 text-lg font-semibold text-[#412B26] xs:text-xl md:text-2xl">
              IT Outsource
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              We offer outsourcing service for our clients. We can save you not
              only resources, time, but money also.
            </p>
          </li>

          <li className="item custom__hidden-slider min-w-[174px]">
            <img
              className="rounded-[20px] object-cover opacity-80 xl:w-[284px] 2xl:w-[328px]"
              src={UnsplashThird}
              alt=""
            />
            <h4 className="mb-6  text-lg font-semibold text-[#5D4A46] xs:text-xl md:text-2xl">
              IT Solutions
            </h4>
            <p className="text-sm font-normal text-[#5D4A46] xs:text-base lg:text-justify lg:text-lg">
              If your problems are troubling you, our solutions might be the
              answer you are looking for.
            </p>
          </li>
        </ul>
      </section>

      <section className="custom-hidden container flex flex-col pt-14 md:gap-6 md:pt-16 lg:gap-4 lg:pt-28">
        <ul className="service-list flex flex-col md:gap-8 lg:gap-0 ">
          <div className="flex justify-between xs:flex-col md:h-[260px] md:flex-row md:gap-[52px] lg:min-h-[400px] lg:gap-32">
            <li className="flex w-full items-center justify-center xs:order-2 xs:p-8 md:order-1 md:w-[270px] md:p-0 lg:min-h-[400px] 1xl:min-w-[450px]">
              <img
                className="h-full w-full xs:max-w-[300px] md:h-[240px] md:w-[270px] md:max-w-none lg:h-[300px] lg:w-[450px] 1xl:h-[400px]"
                src={UnsplashFirst}
                alt=""
              />
            </li>
            <li className="order-2 flex h-[300px] w-full flex-col items-center justify-center xs:order-1 md:h-[240px] lg:h-[400px]">
              <h4 className="mb-4 w-full px-4 text-start text-lg font-semibold text-[#172B4D] xs:text-xl md:text-2xl">
                IT Consultation
              </h4>
              <p className="px-4 text-justify text-sm font-normal text-[#44546F] xs:text-base lg:text-lg">
                Through our experiences and knowledge in the field, there is no
                question that is too hard to answer and no problem too hard to
                solve. We are here to help you find your solution quickly and
                easily. Our members are ready to: (1) provide advice or service
                on how best we can execute your requirements at a given time and
                (2) ensure the solution is compliance with applicable laws or
                regulations, if any.
              </p>
            </li>
          </div>
          <div className="flex justify-between xs:flex-col md:h-[240px] md:flex-row md:gap-[52px] lg:min-h-[400px] lg:gap-32">
            <li className="flex h-[300px] w-full flex-col items-center justify-center md:h-[240px] lg:h-[400px]">
              <h4 className="mb-4 w-full px-4 text-start text-lg font-semibold text-[#172B4D] xs:text-xl md:text-2xl">
                IT Outsource
              </h4>
              <p className="px-4 text-justify text-sm font-normal text-[#44546F] xs:text-base lg:text-lg">
                We offer outsourcing service for our clients. We can save you
                not only resources, time, but money also. From creating a
                software package to an entirely new application on your own
                servers using Git or Mercurial, we make it simple and efficient
                so that even the most demanding projects are easier than ever
                before in terms of developing tools and applications from
                scratch.
              </p>
            </li>

            <li className="flex w-full items-center justify-center xs:order-2 xs:p-8 md:order-1 md:w-[270px] md:p-0 lg:min-h-[400px] 1xl:min-w-[450px]">
              <img
                className="h-full w-full xs:max-w-[300px] md:h-[240px] md:w-[270px] md:max-w-none lg:h-[300px] lg:w-[450px] 1xl:h-[400px]"
                src={UnsplashThird}
                alt=""
              />
            </li>
          </div>
          <div className="flex justify-between xs:flex-col md:h-[240px] md:flex-row md:gap-[52px] lg:min-h-[400px] lg:gap-32">
            <li className="flex w-full items-center justify-center xs:order-2 xs:p-8 md:order-1 md:w-[270px] md:p-0 lg:min-h-[400px] 1xl:min-w-[450px]">
              <img
                className="h-full w-full xs:max-w-[300px] md:h-[240px] md:w-[270px] md:max-w-none lg:h-[300px] lg:w-[450px] 1xl:h-[400px]"
                src={UnsplashSecond}
                alt=""
              />
            </li>
            <li className="flex h-[300px] w-full flex-col items-center justify-center xs:order-1 md:order-2 md:h-[240px] lg:h-[400px]">
              <h4 className="mb-4 w-full px-4 text-start text-lg font-semibold text-[#172B4D] xs:text-xl md:text-2xl">
                IT Solutions
              </h4>
              <p className="px-4 text-justify text-sm font-normal text-[#44546F] xs:text-base lg:text-lg">
                If your problems are troubling you, our solutions might be the
                answer you are looking for. There is virtually no one size fit
                all solution, but we can customize ours to fit what you need.
                Our knowledgeable support staff will help with every aspect of
                your issue and get you through any issues that come up.
              </p>
            </li>
          </div>
        </ul>
      </section>

      <section className="custom-hidden container py-14 md:py-20">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default Service;
