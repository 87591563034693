import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import HighlightModal from '../components/common/modal/high-light/HighlightModal';
import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import SubPageTitle from '../components/layout/SubPageTitle';
import { ModalProvider } from '../contexts/ModalContext';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('custom-show');
        } else {
          entry.target.classList.remove('custom-show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.custom-hidden');
    const hiddenSlider = document.querySelectorAll('.custom__hidden-slider');
    hiddenElements.forEach((el) => observer.observe(el));
    hiddenSlider.forEach((el) => observer.observe(el));
  });

  return (
    <>
      <ModalProvider>
        <HighlightModal />
        <div
          className={`${pathname !== '/' ? 'bgr__sub-page' : 'bgr__home-page'}`}
        >
          <div className="relative h-full overflow-hidden">
            <Header />
            {pathname !== '/' ? (
              <svg
                className="absolute -top-[81px] -left-[480px] hidden"
                width={825}
                height={481}
                viewBox="0 0 825 481"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_3674_4680)">
                  <path
                    d="M-49.5759 -37.3359C217.609 94.7779 518.724 -213.868 574.3 -9.57217C629.877 194.724 182.539 -2.76234 -238.101 309.968C-658.74 622.699 -506.177 -263.11 -49.5759 -37.3359Z"
                    fill="url(#paint0_linear_3674_4680)"
                    fillOpacity="0.3"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3674_4680"
                    x={-731}
                    y="-327.673"
                    width={1556}
                    height="951.288"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation={123}
                      result="effect1_foregroundBlur_3674_4680"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_3674_4680"
                    x1="-353.613"
                    y1="-159.913"
                    x2="336.987"
                    y2="152.818"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0589F0" />
                    <stop offset={1} stopColor="#0589F0" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              ''
            )}

            {pathname !== '/' ? (
              <svg
                className="absolute w-full xs:-bottom-10 xs:h-[100px] md:bottom-0 lg:h-[300px]"
                width={1178}
                height={298}
                viewBox="0 0 1178 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_3674_4679)">
                  <path
                    d="M371.214 580.949C701.567 417.572 593.531 720.874 794.965 750.025C996.398 779.176 1150.67 464.346 703.343 226.604C256.013 -11.1382 -193.338 860.15 371.214 580.949Z"
                    fill="url(#paint0_linear_3674_4679)"
                    fillOpacity="0.4"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_3674_4679"
                    x={-78}
                    y="0.00708008"
                    width={1256}
                    height="936.904"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="92.5"
                      result="effect1_foregroundBlur_3674_4679"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_3674_4679"
                    x1="-11.3511"
                    y1="671.875"
                    x2="842.574"
                    y2="285.252"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0589F0" />
                    <stop offset={1} stopColor="#0589F0" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              ''
            )}
            {pathname === '/' ? <Banner /> : <SubPageTitle />}
          </div>
        </div>

        <Outlet />

        <Footer />
      </ModalProvider>
    </>
  );
};

export default Layout;
