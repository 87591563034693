import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MailForm from '../../components/contact/MailForm';
import ClientSlider from '../../components/home/ClientSlider';

import Address from '../../assets/icons/address-c.svg';
import Mail from '../../assets/icons/mail-c.svg';
import Phone from '../../assets/icons/phone-c.svg';

const Contact = () => {
  return (
    <div className="contact bg-[#F3F6FE]">
      <section className="custom-hidden container pt-14 md:pt-16 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:pt-28">
        <div className="lg:flex lg:flex-col lg:justify-between">
          <div className="mb-7">
            <h3 className="mb-5 text-xl font-bold text-[#44546F] xs:text-2xl md:text-3xl">
              Introduce
            </h3>
            <p className="text-sm font-normal text-[#44546F] xs:text-base lg:text-lg">
              We offer technical consultations, as well as tailored solutions
              and services that promise satisfaction on the first try. We
              believe in building a world-class digital market with our clients
              through research and analysis of their requirements and industry
              needs. Our technology expertise enables us to provide more choices
              for consumers by including customer specific features.
            </p>
          </div>

          <div className="mb-7 lg:mb-0">
            <h4 className="mb-5 text-lg font-medium text-[#172B4D] xs:text-xl md:text-2xl">
              Get in touch with us
            </h4>
            <Link
              className="mb-3 flex items-center text-base font-medium text-[#172B4D] md:font-bold"
              target="_blank"
              rel="noopener noreferrer"
              to="https://goo.gl/maps/3ZAPjhQbVNDRdMCG6"
            >
              <img className="mr-2" src={Address} />
              <span>
                7 Ton That Thuyet Str, Dich Vong Hau, Cau Giay, Ha Noi
              </span>
            </Link>

            <Link
              className="mb-3 flex items-center text-base font-medium text-[#172B4D] md:font-bold"
              to="tel:+84 2432 004 887"
            >
              <img className="mr-2" src={Phone} />
              <span>+84 (2432) 004 887</span>
            </Link>

            <Link
              className="flex items-center text-base font-medium text-[#172B4D] md:font-bold"
              to="mailto: bzcomvina@gmail.com"
            >
              <img className="mr-2" src={Mail} />
              <span>bzcomvina@gmail.com</span>
            </Link>
          </div>
        </div>

        <div className="h-[412px] w-full lg:h-full">
          <MailForm
            className="hover:bg-black"
            classForm="contact-form flex h-full flex-col justify-between"
            classInput="h-full w-full rounded-xl border border-[#C0C0C0] py-4 px-6"
            classTextarea="h-[180px] w-full rounded-xl border border-[#C0C0C0] py-4 px-6"
            classBtn="rounded-xl py-4 text-lg font-medium bg-[#0C66E4] text-white xl:transition-all xl:hover:scale-[1.01]"
          />
        </div>
      </section>

      <section className="custom-hidden pt-14 xs:container md:pt-16 lg:pt-28">
        <div className="mb-[40px] w-full lg:mb-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.1352638983276!2d105.7826301153321!3d21.02727309320405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abb070185ba1%3A0x4e6baae5cfbabd39!2sBZCOM!5e0!3m2!1sen!2s!4v1608629856337!5m2!1sen!2s"
            className="min-h-[412px] w-full shadow-xl xs:rounded-xl md:h-[500px] lg:h-[580px] xl:h-[620px]"
            id="gg-map"
            aria-hidden="false"
            tabIndex="0"
            title="gg-map"
          ></iframe>
        </div>
      </section>

      <section className="custom-hidden py-14 2xl:container md:pt-16 lg:pt-28">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
      <ToastContainer autoClose={5000} />
    </div>
  );
};

export default Contact;
