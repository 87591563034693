import { useEffect, useRef } from 'react';

const Slogan = () => {
  const typeWrite = useRef(null);
  const TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 3000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtType.prototype.tick = function () {
    let i = this.loopNum % this.toRotate.length;
    let fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    let that = this;
    let delta = 60;

    if (this.isDeleting) {
      delta /= 2;
    }
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 2000;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  useEffect(() => {
    const typeInit = () => {
      let elements = typeWrite.current;
      let toRotate = elements.getAttribute('data-type');
      let period = elements.getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements, JSON.parse(toRotate), period);
      }
    };

    setTimeout(() => {
      typeInit();
    }, 2000);
  });

  return (
    <p className="text-xl font-medium xs:text-[20px] xs:leading-[32px] md:text-3xl">
      <span>More than just </span>
      <span
        ref={typeWrite}
        className="type-write "
        data-type='[ "consultants", "engineers"]'
      >
        <span className="wrap"></span>
      </span>
    </p>
  );
};

export default Slogan;
