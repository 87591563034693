import { useState } from 'react';
import ClientSlider from '../../components/home/ClientSlider';

import AboutFirst from '../../assets/images/about-1.png';
import AboutThird from '../../assets/images/about-3.png';
import AboutFour from '../../assets/images/about-4.png';
import HistoryAct from '../../assets/images/history-active.png';
import History from '../../assets/images/history.png';

const historyBz = [
  {
    year: 2024,
    content: [
      'Food Safety Information System Development Project for VFA from MFDS / Local Coordinator and System Development',
      'Penta Security Inc. - Offshoring',
      'MES Solution Development'
    ]
  },
  {
    year: 2023,
    content: [
      'Da Lat Smart Farm Project',
      'Vietnam Food Safety Information System Development (4th Phase)',
      'Vietnam Smart City Certification Monitoring System Development',
      'Smart Video Calling Project (Japan)'
    ]
  },
  {
    year: 2022,
    content: [
      'Food Safety Information System Development Project for VFA from MFDS / Local Coordinator and System Development',
      'Enhancing financial transaction processing capacity for Core Banking System (Vietnam AgriBank)',
      'Information System Audit Services for Project for Smart City Establishment of Quang Nam Province (Tam Ky City), Vietnam',
      'PMO Service for Information System of the National Technician Qualification in Vietnam',
      'MK Live Implementation for Vi Sang Edu Vietnam',
      'Digital Transformation Online Forum 2022 (Vietnam-Korea) - Organizer'
    ]
  },
  {
    year: 2021,
    content: [
      'Food Safety Information System Development Project for VFA from MFDS / Local Coordinator and Development Partner',
      'Online Opinion Analysis of Vietnamese on South Korea after Covid-19 (Ministry of Foreign Affairs)',
      'System Maintenance and Improvement Project for Vi Sang Edu Vietnam (Cont’d)',
      'Pre Feasibility Study for MPI (Ministry of Planning and Investment) with KOICA',
      'Outsourcing of the illustrate for Korean Text Book'
    ]
  },
  {
    year: 2020,
    content: [
      'eGov Consulting for Supreme People’s Court of Vietnam / Coordinator and Consulting',
      'Research on technology business for family companies to advance into new southern countries (HoSeo Univ)',
      'Vietnam real-time financial network (NAPAS) connection project Kookmin Bank',
      'Outsourcing with SimTech Co., Ltd.',
      'Outsourcing with RSN Co., Ltd.',
      'NIPA Research Project for Mind Map Software Solution',
      'Pilot System : Mind Map Collaboration System (Vietnam Gov’)',
      'NotiU – Vietnam Central Government',
      'Development – Web Crawling Solution'
    ]
  },
  {
    year: 2019,
    content: [
      {
        year: 2019,
        content: [
          'Outsourcing with RSN Co., Ltd.',
          'Consulting ISP for Supreme People’s Court of Vietnam',
          'Development eXCampus with tomato System Co., Ltd.',
          'Member of the Vietnam-Korea Friendship Association.'
        ]
      },
      {
        year: 2018,
        content: [
          'EAMS for Vietnam National University Hanoi',
          'Development eXCampus with tomato System Co., Ltd.',
          'Biometrics Project with Bac Ninh Police',
          'eLearning : Vietnam Marine Univ - Crew Manning Center'
        ]
      },
      {
        year: 2017,
        content: [
          'Outsourcing with IamZ Co., Ltd.',
          'Development eXCampus with tomato System Co., Ltd.',
          'eLearning : HCM City, Dept. of Education',
          'eLearning : People’s security Univ in Hanoi',
          'eLearning : Bach Mai Hospital'
        ]
      },
      {
        year: 2015,
        content: [
          'Started service for the S&B (Search Bidding) Service',
          'eLearning : Univ of Communication and Transport',
          'eLearning : Agribank, Vietnam Airline, Da Nang City'
        ]
      }
    ]
  }
];

const About = () => {
  const [yearActive, setYearActive] = useState(2024);
  const [content, setContent] = useState(historyBz[0]?.content);
  const [hoverYear, setHoverYear] = useState();

  const showContent = (selectYear) => {
    setYearActive(selectYear);
    for (let index = 0; index < historyBz.length; index++) {
      const data = historyBz[index];
      if (selectYear === data?.year) {
        setContent(data?.content);
      }
    }
  };

  return (
    <div className="about bg-white">
      {/* Intro */}
      <section className="custom-hidden container pt-14 pb-4 md:pt-16 lg:pt-28 lg:pb-20">
        <div className="xl:grid-rows-2 grid-cols-1 xl:grid xl:grid-flow-col xl:gap-8">
          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#172B4D]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold 1xl:text-[40px] 1xl:leading-[40px]">
              Who we are
            </h4>
            <p className="text-sm font-normal text-[#44546F] xs:text-base lg:text-lg 1xl:text-[16px] 1xl:leading-[24px]">
              BZCOM was founded in Hanoi in 2017. In BZCOM, we believe in
              “consensus - communication - consideration” philosophy. We always
              consent with our employees, partners, as well as clients as the
              work is being carried out. To avoid unneccesary misunderstanding,
              communication is maintained clear and indispensable at work.
              Finally, we treat our employees, partners, and clients with utmost
              consideration and respect.
            </p>
          </div>

          <div className="mb-6 lg:mb-0 lg:flex lg:flex-col lg:justify-center">
            <h4 className="mb-4 text-lg font-medium text-[#172B4D]  xs:text-xl md:text-2xl lg:text-2xl lg:font-bold 1xl:text-[40px] 1xl:leading-[40px]">
              What we do
            </h4>
            <p className="text-sm font-normal text-[#44546F] xs:text-base lg:text-lg 1xl:text-[16px] 1xl:leading-[24px]">
              To keep it short, we consult and we develop. We have been working
              with various customers who come from all sorts of areas, and we do
              understand that IT is not an easy topic to deal with. We help our
              customers understanding what they want and what they need, then
              offer to turn their wishes into reality.
            </p>
          </div>

          <div className="row-span-3 lg:block lg:pt-4">
            <img
              className="hidden max-h-[450px] rounded object-cover xl:flex"
              src={AboutThird}
              alt=""
            />
            <img
              className="h-[400px] w-full rounded object-cover xl:hidden"
              src={AboutFour}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* Background image */}
      <section className="custom-hidden mx-auto hidden max-w-[375px] px-[1rem] pt-14 sm:max-w-none sm:px-0 md:pt-16 lg:pt-28">
        <img
          className="h-[364px] rounded-2xl object-cover sm:h-full sm:rounded-none"
          src={AboutFirst}
          alt=""
        />

        <div className="container mt-[-65px] text-2xl font-semibold lg:mt-[-112px] lg:text-5xl lg:font-bold">
          <p className="text-[#98DAFF] opacity-60">Our History</p>
          <p className="mt-[-12px] text-[#2081B7] opacity-80">Our History</p>
          <p className="mt-[-12px] text-white">Our History</p>
          <p className="mt-[-12px] text-[#3170A9] opacity-70">Our History</p>
          <p className="mt-[-12px] text-[#325F87] opacity-40">Our History</p>
        </div>
      </section>

      {/* History */}
      <div className="bg-[#F7F7F7]">
        <section className="custom-hidden history container mx-auto max-w-none pt-14 pb-4 md:pt-16 lg:pt-20">
          <div className="flex pb-10">
            <h4 className="text-[40px] font-[700] leading-[56px]">
              Our History
            </h4>
          </div>
          <div className="flex bg-[#F7F7F7] md:grid md:grid-cols-6 md:gap-14">
            {/* Years */}
            <ul className="years mr-6 grid w-[80px] justify-center xs:h-[620px] md:h-[740px] lg:col-span-2 lg:mr-0 lg:w-full lg:grid-cols-2 lg:grid-rows-14">
              {/* 2024 */}
              <li className="mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2024)}
                  onMouseEnter={() => setHoverYear(2024)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2024
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2024 &&
                    hoverYear === 2024 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:text-[26px] lg:leading-[26px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2024
                </h4>
              </li>
              <li className="z-10 max-w-[80px]">
                {yearActive === 2024 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2024)}
                    onMouseEnter={() => setHoverYear(2024)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2024 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>

              <li className="mt-[-55%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:-rotate-[10deg]  xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-[400%]"></li>

              {/* 2023 */}
              <li className="row-start-3 mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2023)}
                  onMouseEnter={() => setHoverYear(2023)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2023
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2023 &&
                    hoverYear === 2023 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:text-[26px] lg:leading-[26px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2023
                </h4>
              </li>
              <li className="z-10 row-start-3 lg:justify-self-center">
                {yearActive === 2023 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2023)}
                    onMouseEnter={() => setHoverYear(2023)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2023 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>

              <li className="mt-[-55%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.85] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg] 2xl:-translate-x-[300%]"></li>

              {/* 2022 */}
              <li className="row-start-5 mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2022)}
                  onMouseEnter={() => setHoverYear(2022)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2022
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2022 &&
                    hoverYear === 2022 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:text-[26px] lg:leading-[26px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2022
                </h4>
              </li>
              <li className="z-10 row-start-5">
                {yearActive === 2022 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2022)}
                    onMouseEnter={() => setHoverYear(2022)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2022 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>

              <li className="mt-[-55%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-70 md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4  lg:-rotate-[10deg] xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

              {/* 2021 */}
              <li className="row-start-7 mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2021)}
                  onMouseEnter={() => setHoverYear(2021)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2021
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2021 &&
                    hoverYear === 2021 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:text-[26px] lg:leading-[26px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2021
                </h4>
              </li>
              <li className="z-10 row-start-7 lg:justify-self-center">
                {yearActive === 2021 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2021)}
                    onMouseEnter={() => setHoverYear(2021)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2021 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>

              <li className="mt-[-55%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-[0.65] md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4 lg:rotate-[10deg] xl:-translate-x-6 xl:rotate-[38deg]"></li>

              {/* 2020 */}
              <li className="row-start-9 mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2020)}
                  onMouseEnter={() => setHoverYear(2020)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2020
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2020 &&
                    hoverYear === 2020 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:text-[26px] lg:leading-[26px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2020
                </h4>
              </li>
              <li className="z-10 row-start-9">
                {yearActive === 2020 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2020)}
                    onMouseEnter={() => setHoverYear(2020)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2020 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>

              <li className="mt-[-55%] block h-[300%] w-2 justify-self-center bg-[#D6D6D6] opacity-50 md:w-3 lg:col-start-2 lg:mt-[-10%] lg:h-[200%] lg:-translate-x-4  lg:-rotate-[10deg] xl:-translate-x-6 xl:-rotate-[40deg] 2xl:-translate-x-9"></li>

              {/* 2016-2017 */}
              <li className="row-start-11 mr-6 hidden self-center justify-self-end lg:block">
                <h4
                  onClick={() => showContent(2019)}
                  onMouseEnter={() => setHoverYear(2019)}
                  onMouseLeave={() => setHoverYear()}
                  className={`${
                    yearActive === 2019
                      ? 'title-active'
                      : 'cursor-pointer xl:transition-all xl:hover:scale-105'
                  } ${
                    yearActive !== 2019 &&
                    hoverYear === 2019 &&
                    'title-active xl:scale-105 xl:transition-all'
                  }  font-gilroy text-xl font-extrabold text-[#8490A1] lg:w-[148px] lg:text-[26px] lg:leading-[26px] xl:w-[205px] xl:text-[36px] xl:leading-[37.8px]`}
                >
                  2014-2019
                </h4>
              </li>
              <li className="z-10 row-start-11 lg:justify-self-center">
                {yearActive === 2019 ? (
                  <img
                    className="img-active w-full min-w-[80px] max-w-[100px] object-cover"
                    src={HistoryAct}
                    alt=""
                  />
                ) : (
                  <img
                    onClick={() => showContent(2019)}
                    onMouseEnter={() => setHoverYear(2019)}
                    onMouseLeave={() => setHoverYear()}
                    className={`${
                      hoverYear === 2019 &&
                      'img-default scale-105 transition-all'
                    } w-full min-w-[80px] max-w-[100px] cursor-pointer object-cover xl:transition-all xl:hover:scale-105`}
                    src={History}
                    alt=""
                  />
                )}
              </li>
            </ul>

            {/* Content */}
            <div className="content col-span-4 pb-8 xs:h-[680px] sm:pb-0 lg:h-[600px]">
              {yearActive !== 2019 ? (
                <p className="mb-4 font-gilroy text-[24px] font-extrabold leading-[25.2px] text-[#0C66E4] md:text-[32px] md:leading-[33.6px] lg:hidden">
                  {yearActive}
                </p>
              ) : (
                ''
              )}

              <div
                className={`${yearActive === 2022 && 'h-[78%]'} ${
                  yearActive === 2020 && 'h-[90%]'
                } ${
                  yearActive === 2019 && 'h-full'
                } overflow-hidden overflow-y-auto`}
              >
                {yearActive !== 2019 ? (
                  <p className="mb-4 hidden text-2xl font-[600] md:text-[32px] md:leading-[33.6px] lg:inline-block lg:font-[800] lg:text-[#0C66E4] xl:text-black 1xl:text-[28px] 1xl:leading-[24px]">
                    {yearActive}
                  </p>
                ) : (
                  ''
                )}
                {yearActive === 2022 && (
                  <p className="mb-6 text-lg font-medium text-[#0C66E4] xs:text-xl md:text-2xl">
                    Listen to you, answer with solutions.
                  </p>
                )}
                {content?.map((data, index) => {
                  if (yearActive === 2019) {
                    return (
                      <div key={index} className="md:pb-10">
                        <p className="mb-4 text-2xl font-[600] xs:text-[#0C66E4] md:text-[32px] md:leading-[33.6px] lg:text-[#172B4D] 1xl:text-[28px] 1xl:leading-[24px]">
                          {data.year !== 2017 && data.year !== 2015
                            ? data.year
                            : ''}
                          {data.year === 2017 &&
                            '07/2017 : Established BzCom Company Limited'}
                          {data.year === 2015 &&
                            '05/2014 : Established MMG Vietnam JSC'}
                        </p>

                        {data?.content?.map((dataContent, key) => {
                          return (
                            <p
                              key={key}
                              className="mb-6 text-sm font-normal text-[#333333] xs:text-base md:text-[16px] md:leading-[24px] 1xl:text-[20px] 1xl:leading-[24px]"
                            >
                              {dataContent}
                            </p>
                          );
                        })}
                      </div>
                    );
                  } else {
                    return (
                      <p
                        key={index}
                        className="mb-6 text-sm font-normal text-[#333333] xs:text-base md:max-w-[550px] md:text-[16px] lg:max-w-[760px] lg:leading-[24px] 1xl:text-[20px] 1xl:leading-[24px]"
                      >
                        {data}
                      </p>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Client */}
      <section className="custom-hidden container py-14 md:py-20">
        <ClientSlider
          infinite={true}
          autoplay={true}
          autoplayLg={true}
          classImage={
            'h-16 grayscale hover:grayscale-0 opacity-80 xl:ml-[-64px] 2xl:ml-[-44px] xl:transition-all xl:hover:scale-95'
          }
        />
      </section>
    </div>
  );
};

export default About;
